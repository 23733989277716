@charset "UTF-8";
@import url(base.css);
@import url(common.css);
@import url(page2.css);
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
@media print, screen and (min-width: 768px) {
  /*
  404
  -------------------------------------*/
  /*
  ホーム
  -------------------------------------*/
  #PageIndex.pageIndex #Main .caution {
    font-size: 16px;
    line-height: 25px;
    color: #888;
    position: relative;
    padding-left: 24px; }
    #PageIndex.pageIndex #Main .caution span {
      display: block;
      position: absolute;
      top: 0;
      left: 0; }
  #PageIndex.pageIndex #Main .contBox {
    margin-bottom: 95px;
    padding-top: 94px; }
    #PageIndex.pageIndex #Main .contBox .titleTxt {
      margin-bottom: 18px; }
      #PageIndex.pageIndex #Main .contBox .titleTxt + p {
        font-size: 24px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 1.8px; }
    #PageIndex.pageIndex #Main .contBox:nth-of-type(2n) {
      margin-bottom: 0;
      background: #f3f4fb; }
      #PageIndex.pageIndex #Main .contBox:nth-of-type(2n) .innerBasic {
        padding-bottom: 115px; }
      #PageIndex.pageIndex #Main .contBox:nth-of-type(2n) .titleTxt h3 {
        background: #f3f4fb; }
  #PageIndex.pageIndex #Main #ContBox01 {
    padding-top: 97px; }
    #PageIndex.pageIndex #Main #ContBox01 .titleTxt + p span {
      font-weight: 700; }
      #PageIndex.pageIndex #Main #ContBox01 .titleTxt + p span.note {
        color: #d63030; }
    #PageIndex.pageIndex #Main #ContBox01 .contSubBox {
      position: relative;
      width: 320px;
      float: left;
      margin-top: 45px; }
      #PageIndex.pageIndex #Main #ContBox01 .contSubBox + .contSubBox {
        margin-left: 20px; }
      #PageIndex.pageIndex #Main #ContBox01 .contSubBox > span {
        text-indent: -9999px;
        display: block;
        width: 100%;
        height: 230px; }
      #PageIndex.pageIndex #Main #ContBox01 .contSubBox h4 {
        width: 240px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        background: #277977;
        color: #fff;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        letter-spacing: 4px; }
      #PageIndex.pageIndex #Main #ContBox01 .contSubBox div {
        height: 260px;
        background: #f3f3f3;
        width: 100%;
        padding: 50px 20px 25px;
        position: relative;
        top: -25px; }
        #PageIndex.pageIndex #Main #ContBox01 .contSubBox div p:first-child {
          font-size: 20px;
          line-height: 30px;
          font-weight: 700; }
        #PageIndex.pageIndex #Main #ContBox01 .contSubBox div ul li {
          font-size: 22px;
          line-height: 36px;
          font-weight: 700;
          width: 185px;
          margin: 0 auto;
          padding-left: 30px;
          background: url(../img/contents/index_icon01.png) no-repeat top 4px left;
          background-size: 25px auto; }
        #PageIndex.pageIndex #Main #ContBox01 .contSubBox div p + p, #PageIndex.pageIndex #Main #ContBox01 .contSubBox div ul + p {
          font-size: 16px;
          line-height: 25px;
          margin-top: 8px; }
    #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_01 {
      background: url(../img/contents/index_img01.jpg) no-repeat top center;
      background-size: 237px auto; }
      #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_01 > span {
        background: url(../img/contents/index_txt01.png) no-repeat top 10px left;
        background-size: 72px auto; }
    #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_02 {
      background: url(../img/contents/index_img02.jpg) no-repeat top center;
      background-size: 237px auto; }
      #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_02 > span {
        background: url(../img/contents/index_txt02.png) no-repeat top 10px left;
        background-size: 72px auto; }
      #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_02 h4 {
        background: #135a7e; }
    #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_03 {
      background: url(../img/contents/index_img03.jpg) no-repeat top center;
      background-size: 237px auto; }
      #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_03 > span {
        background: url(../img/contents/index_txt03.png) no-repeat top 10px left;
        background-size: 72px auto; }
      #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_03 h4 {
        background: #483f89; }
      #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_03 div p:first-of-type {
        letter-spacing: -0.8px; }
  #PageIndex.pageIndex #Main #ContBox02 .innerBasic p.caution {
    text-align: center;
    letter-spacing: 0.7px; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic p.caution span {
      position: static;
      display: inline;
      padding-right: 0.2em; }
  #PageIndex.pageIndex #Main #ContBox02 .innerBasic .titleTxt + p {
    margin-bottom: 5px; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic .titleTxt + p span {
      font-weight: 700; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .titleTxt + p span.note {
        color: #f68813; }
  #PageIndex.pageIndex #Main #ContBox02 .innerBasic > dl {
    width: 500px;
    display: table;
    margin: 34px auto 40px; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic > dl dt, #PageIndex.pageIndex #Main #ContBox02 .innerBasic > dl dd {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
      height: 60px;
      font-size: 20px;
      line-height: 1;
      font-weight: 700;
      text-align: center; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic > dl dt {
      background: #18275d;
      color: #fff;
      letter-spacing: 1.5px; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic > dl dd {
      background: #fff; }
  #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox {
    margin-top: 40px; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .clickTitle {
      position: relative; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .clickTitle h4 {
        width: 100%;
        padding: 0 100px;
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        font-weight: 700;
        background: #18275d;
        color: #fff;
        text-align: center;
        letter-spacing: 3px; }
        #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .clickTitle h4 span span {
          font-size: 20px; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .clickTitle > span {
        display: block;
        width: 100px;
        height: 60px;
        line-height: 60px;
        position: absolute;
        top: 0;
        right: 0;
        background: #5569b2;
        text-align: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer; }
        #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .clickTitle > span:before {
          content: "";
          display: inline-block;
          vertical-align: top;
          width: 16px;
          height: 60px;
          background: url(../img/arrow_08.png) no-repeat left center;
          background-size: 16px auto;
          margin-right: 10px; }
        #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .clickTitle > span:hover {
          opacity: 0.7; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox.open .clickTitle > span:before {
      background: url(../img/arrow_09.png) no-repeat left center;
      background-size: 16px auto; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox {
      font-size: 0;
      margin-top: 2px; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox figure, #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div {
        display: inline-block;
        vertical-align: top; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox figure {
        width: 385px;
        background: #fff;
        padding: 10px; }
        #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox figure img {
          width: 100%;
          height: auto; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div {
        width: 613px;
        margin-left: 2px; }
        #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl {
          width: 100%;
          display: table;
          background: #fff; }
          #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl + dl {
            margin-top: 2px; }
          #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl dt, #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl dd {
            display: table-cell;
            vertical-align: middle;
            height: 60px;
            line-height: 60px;
            font-size: 20px;
            letter-spacing: 1px; }
          #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl dt {
            padding-left: 20px; }
            #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl dt span {
              font-size: 16px;
              line-height: 1; }
          #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl dd {
            padding-right: 20px;
            font-weight: 700;
            text-align: right; }
  #PageIndex.pageIndex #Main #ContBox03 .mapArea {
    font-size: 0;
    margin-top: 10px; }
    #PageIndex.pageIndex #Main #ContBox03 .mapArea figure {
      display: inline-block;
      vertical-align: middle;
      width: 436px; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea figure img {
        width: 100%;
        height: auto; }
    #PageIndex.pageIndex #Main #ContBox03 .mapArea div {
      display: inline-block;
      vertical-align: middle;
      width: 499px;
      margin-left: 65px; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl + dl {
        margin-top: 20px; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl dt, #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl dd {
        font-size: 20px;
        line-height: 34px; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl dt {
        font-weight: 700;
        padding-left: 18px;
        position: relative;
        color: #135a7e; }
        #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl dt:before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background: #135a7e;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(2) dt {
        color: #383075; }
        #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(2) dt:before {
          background: #383075; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(3) dt {
        color: #1c8e8b; }
        #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(3) dt:before {
          background: #1c8e8b; }
  #PageIndex.pageIndex #Main #ContBox04 ol {
    list-style: none;
    margin-top: 50px; }
    #PageIndex.pageIndex #Main #ContBox04 ol li {
      width: 100%;
      padding-left: 240px;
      height: 240px;
      margin-bottom: 40px; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(2n) {
        padding-right: 240px;
        padding-left: 0; }
      #PageIndex.pageIndex #Main #ContBox04 ol li + li {
        position: relative; }
        #PageIndex.pageIndex #Main #ContBox04 ol li + li:before {
          content: "";
          display: inline-block;
          position: absolute;
          top: -30px;
          left: 0;
          right: 0;
          margin: auto;
          width: 0;
          height: 0;
          border-left: 24px solid transparent;
          border-right: 24px solid transparent;
          border-bottom: 0 solid transparent;
          border-top: 21px solid #cacce3;
          vertical-align: middle; }
      #PageIndex.pageIndex #Main #ContBox04 ol li dl {
        height: 240px;
        background: #fff;
        overflow: hidden; }
        #PageIndex.pageIndex #Main #ContBox04 ol li dl dt {
          width: 100%;
          height: 80px;
          line-height: 80px;
          font-size: 24px;
          font-weight: 700;
          padding-left: 28px;
          color: #fff; }
          #PageIndex.pageIndex #Main #ContBox04 ol li dl dt span span {
            display: none; }
        #PageIndex.pageIndex #Main #ContBox04 ol li dl dd {
          background: #fff;
          padding: 22px 30px; }
          #PageIndex.pageIndex #Main #ContBox04 ol li dl dd .caution {
            color: #d63030;
            font-weight: 700;
            margin-top: 10px; }
          #PageIndex.pageIndex #Main #ContBox04 ol li dl dd figure {
            margin-top: 10px; }
          #PageIndex.pageIndex #Main #ContBox04 ol li dl dd img {
            width: 540px;
            height: auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:first-child {
        background: url(../img/contents/index_img09.jpg) no-repeat top left;
        background-size: 240px auto; }
        #PageIndex.pageIndex #Main #ContBox04 ol li:first-child dl dt {
          background: #21468f url(../img/contents/index_txt04.png) no-repeat center right 18px;
          background-size: 154px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(2) {
        background: url(../img/contents/index_img10.jpg) no-repeat top right;
        background-size: 240px auto; }
        #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(2) dl dt {
          background: #21468f url(../img/contents/index_txt05.png) no-repeat center right 18px;
          background-size: 154px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(3) {
        background: url(../img/contents/index_img11.jpg) no-repeat top left;
        background-size: 240px auto; }
        #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(3) dl dt {
          background: #21468f url(../img/contents/index_txt06.png) no-repeat center right 18px;
          background-size: 154px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(3) {
        background: url(../img/contents/index_img11.jpg) no-repeat top left;
        background-size: 240px auto; }
        #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(3) dl dt {
          background: #21468f url(../img/contents/index_txt06.png) no-repeat center right 18px;
          background-size: 154px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(4) {
        background: url(../img/contents/index_img13.jpg) no-repeat top right;
        background-size: 240px auto; }
        #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(4) dl dt {
          background: #21468f url(../img/contents/index_txt07.png) no-repeat center right 18px;
          background-size: 154px auto; }
  #PageIndex.pageIndex #Main #ContBox04 .clickBox + .clickBox {
    margin-top: 20px; }
  #PageIndex.pageIndex #Main #ContBox04 .clickBox > dt {
    background: #18275d;
    color: #fff;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    font-size: 16px;
    color: #fff;
    letter-spacing: 1px; }
    #PageIndex.pageIndex #Main #ContBox04 .clickBox > dt span {
      display: none; }
  #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd {
    display: block !important;
    border: 1px solid #18275d;
    padding: 15px 20px;
    font-size: 16px;
    line-height: 25px; }
    #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd p {
      font-size: 16px;
      line-height: 25px; }
    #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl {
      font-size: 0;
      margin: 7px 0 6px;
      background: #e4e5f0; }
      #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dt, #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dd {
        font-size: 16px;
        line-height: 25px;
        display: inline-block;
        vertical-align: middle;
        padding: 16px 0; }
      #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dt {
        font-weight: 700;
        padding-left: 20px;
        padding-right: 60px;
        min-width: 100px; }
      #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dd ul {
        font-size: 0;
        display: table; }
        #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dd ul li {
          display: table-cell;
          vertical-align: middle;
          font-size: 16px;
          line-height: 25px;
          padding-left: 18px;
          padding-right: 30px;
          position: relative; }
          #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dd ul li:before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 14px;
            background: #222;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0; }
    #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div + div {
      border-top: 1px solid #18275d;
      margin-top: 15px;
      padding-top: 15px; }
      #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div + div > div {
        font-size: 0; }
        #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div + div > div dl {
          width: 474px;
          display: inline-block; }
          #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div + div > div dl + dl {
            margin-left: 10px; }
            #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div + div > div dl + dl dt {
              padding-right: 35px; }
  #PageIndex.pageIndex #Main #ContBox05 .titleTxt {
    margin-bottom: 45px; }
  #PageIndex.pageIndex #Main #ContBox05 dl dt {
    height: 100px;
    line-height: 100px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    position: relative; }
    #PageIndex.pageIndex #Main #ContBox05 dl dt:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 0 solid transparent;
      border-top: 10px solid #243a88;
      vertical-align: middle; }
  #PageIndex.pageIndex #Main #ContBox05 dl dd {
    font-size: 20px;
    line-height: 34px;
    font-weight: 700;
    text-align: center;
    background: #f3f3f3;
    padding: 23px 0; }
  #PageIndex.pageIndex #Main #ContBox05 dl:first-of-type dt {
    background: #243a88 url(../img/contents/index_img14.png) no-repeat center center;
    background-size: 77px auto; }
  #PageIndex.pageIndex #Main #ContBox05 dl:nth-of-type(2) dt {
    background: #243a88 url(../img/contents/index_img15.png) no-repeat center center;
    background-size: 87px auto; }
  #PageIndex.pageIndex #Main #ContBox05 dl:nth-of-type(3) dt {
    background: #243a88 url(../img/contents/index_img16.png) no-repeat center center;
    background-size: 87px auto; }
  #PageIndex.pageIndex #Main #ContBox05 dl:nth-of-type(4) dt {
    background: #243a88 url(../img/contents/index_img17.png) no-repeat center center;
    background-size: 75px auto; }
  #PageIndex.pageIndex #Main #ContBox05 dl:nth-of-type(5) dt {
    background: #243a88 url(../img/contents/index_img18.png) no-repeat center center;
    background-size: 94px auto; }
  #PageIndex.pageIndex #Main #ContBox06 .titleTxt {
    margin-bottom: 50px; }
  #PageIndex.pageIndex #Main #ContBox06 dl {
    display: table;
    width: 100%;
    table-layout: fixed; }
    #PageIndex.pageIndex #Main #ContBox06 dl + dl {
      margin-top: 10px; }
    #PageIndex.pageIndex #Main #ContBox06 dl dt, #PageIndex.pageIndex #Main #ContBox06 dl dd {
      display: table-cell;
      vertical-align: middle;
      table-layout: fixed;
      height: 70px;
      line-height: 70px; }
    #PageIndex.pageIndex #Main #ContBox06 dl dd {
      width: 227px; }
      #PageIndex.pageIndex #Main #ContBox06 dl dd ul {
        font-size: 0; }
        #PageIndex.pageIndex #Main #ContBox06 dl dd ul li {
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          font-size: 16px;
          line-height: 70px; }
          #PageIndex.pageIndex #Main #ContBox06 dl dd ul li.category {
            width: 95px;
            background: #287a78;
            color: #fff;
            font-weight: 700; }
            #PageIndex.pageIndex #Main #ContBox06 dl dd ul li.category.news {
              background: #327699; }
          #PageIndex.pageIndex #Main #ContBox06 dl dd ul li.date {
            width: 132px;
            background: #e4e5f0;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            letter-spacing: 2.2px; }
    #PageIndex.pageIndex #Main #ContBox06 dl dt {
      width: 773px;
      background: #fff; }
      #PageIndex.pageIndex #Main #ContBox06 dl dt a {
        display: block;
        padding: 0 20px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 20px;
        color: #1e3a9e;
        text-decoration: underline; }
        #PageIndex.pageIndex #Main #ContBox06 dl dt a:hover {
          text-decoration: none; }
  #PageIndex.pageIndex #Main #ContBox06 .linkBtn {
    width: 370px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    margin: 40px auto 0; }
    #PageIndex.pageIndex #Main #ContBox06 .linkBtn a {
      display: block;
      width: 100%;
      height: 100%;
      background: #5569b2 url(../img/aroow_01.png) no-repeat center right 20px;
      background-size: 8px auto;
      font-size: 18px;
      color: #fff;
      letter-spacing: 1px; }
      #PageIndex.pageIndex #Main #ContBox06 .linkBtn a:hover {
        opacity: 0.7; }
  /*
  会社案内 pc
  -------------------------------------*/
  #PageCompany.pageIndex #Main {
    margin: 54px 0 120px; }
    #PageCompany.pageIndex #Main .contBox {
      margin-bottom: 88px; }
    #PageCompany.pageIndex #Main #ContBox01 .txt {
      width: 750px;
      float: left; }
      #PageCompany.pageIndex #Main #ContBox01 .txt p + p {
        margin-top: 20px; }
      #PageCompany.pageIndex #Main #ContBox01 .txt p.note {
        font-size: 24px;
        line-height: 40px;
        color: #d63030;
        font-weight: 700; }
    #PageCompany.pageIndex #Main #ContBox01 dl {
      width: 210px;
      float: right;
      margin-top: 7px; }
      #PageCompany.pageIndex #Main #ContBox01 dl dt {
        text-indent: -9999px;
        background: url(../img/logo.png) no-repeat top left;
        background-size: 210px auto;
        width: 210px;
        height: 94px; }
      #PageCompany.pageIndex #Main #ContBox01 dl dd {
        font-size: 15px;
        line-height: 19px;
        font-weight: 700;
        text-align: right;
        margin-top: 6px;
        letter-spacing: 1px; }
    #PageCompany.pageIndex #Main #ContBox02 dl {
      font-size: 0;
      background: #f3f3f3;
      padding: 18px 0; }
      #PageCompany.pageIndex #Main #ContBox02 dl:nth-of-type(2n) {
        background: #fff; }
      #PageCompany.pageIndex #Main #ContBox02 dl dd, #PageCompany.pageIndex #Main #ContBox02 dl dt {
        display: inline-block;
        vertical-align: top;
        font-size: 20px;
        line-height: 34px; }
      #PageCompany.pageIndex #Main #ContBox02 dl dt {
        width: 190px;
        padding-left: 20px;
        font-weight: 700;
        color: #1c2155; }
      #PageCompany.pageIndex #Main #ContBox02 dl dd {
        width: 810px;
        letter-spacing: 1px; }
        #PageCompany.pageIndex #Main #ContBox02 dl dd a {
          text-decoration: underline; }
          #PageCompany.pageIndex #Main #ContBox02 dl dd a:hover {
            text-decoration: none; }
          #PageCompany.pageIndex #Main #ContBox02 dl dd a.telLink {
            color: #222;
            text-decoration: none; }
    #PageCompany.pageIndex #Main #ContBox03 .titleTxt {
      margin-bottom: 19px; }
    #PageCompany.pageIndex #Main #ContBox03 p {
      text-align: center;
      font-size: 24px;
      line-height: 40px; }
    #PageCompany.pageIndex #Main #ContBox03 .mapArea {
      font-size: 0;
      margin-top: 10px; }
      #PageCompany.pageIndex #Main #ContBox03 .mapArea figure {
        display: inline-block;
        vertical-align: middle;
        width: 436px; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea figure img {
          width: 100%;
          height: auto; }
      #PageCompany.pageIndex #Main #ContBox03 .mapArea div {
        display: inline-block;
        vertical-align: middle;
        width: 499px;
        margin-left: 65px; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl + dl {
          margin-top: 20px; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl dt, #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl dd {
          font-size: 20px;
          line-height: 34px; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl dt {
          font-weight: 700;
          padding-left: 18px;
          position: relative;
          color: #135a7e; }
          #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl dt:before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background: #135a7e;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(2) dt {
          color: #383075; }
          #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(2) dt:before {
            background: #383075; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(3) dt {
          color: #1c8e8b; }
          #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(3) dt:before {
            background: #1c8e8b; }
  /*
  お問い合わせ pc 共通
  -------------------------------------*/
  #PageContact #Main {
    margin-top: 49px; }
    #PageContact #Main #ContBox01 > div.innerBasic p {
      font-size: 24px;
      line-height: 40px;
      text-align: center; }
      #PageContact #Main #ContBox01 > div.innerBasic p span {
        color: #f68813;
        font-weight: 700; }
      #PageContact #Main #ContBox01 > div.innerBasic p.note {
        color: #d63030;
        font-size: 16px;
        font-weight: 700; }
    #PageContact #Main #ContBox01 > div.innerBasic .telBox {
      width: 490px;
      margin: 27px auto 20px;
      padding: 18px 0 27px;
      border: 1px solid #dddddd; }
      #PageContact #Main #ContBox01 > div.innerBasic .telBox dl {
        text-align: center; }
        #PageContact #Main #ContBox01 > div.innerBasic .telBox dl dt {
          font-size: 54px;
          line-height: 67px;
          color: #0c0f3a;
          font-family: 'Lato', sans-serif;
          font-weight: 400;
          letter-spacing: 0.8px; }
          #PageContact #Main #ContBox01 > div.innerBasic .telBox dl dt:before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 44px;
            height: 26px;
            background: url(../img/tel_icon.png) no-repeat bottom left;
            background-size: 44px auto;
            margin-right: 4px; }
        #PageContact #Main #ContBox01 > div.innerBasic .telBox dl dd {
          text-align: center;
          font-size: 16px;
          line-height: 1;
          color: #666;
          letter-spacing: 0.5px; }
      #PageContact #Main #ContBox01 > div.innerBasic .telBox + div {
        text-align: center; }
        #PageContact #Main #ContBox01 > div.innerBasic .telBox + div a {
          font-size: 20px;
          color: #1e3a9e;
          text-decoration: underline; }
          #PageContact #Main #ContBox01 > div.innerBasic .telBox + div a:hover {
            text-decoration: none; }
    #PageContact #Main #ContBox01 #ContSubBox01 {
      margin-top: 98px;
      margin-bottom: 100px; }
      #PageContact #Main #ContBox01 #ContSubBox01 form dl {
        width: 100%;
        display: table;
        table-layout: fixed;
        border-bottom: 1px solid #eeeeee;
        color: #222; }
        #PageContact #Main #ContBox01 #ContSubBox01 form dl:first-of-type {
          border-top: 1px solid #eeeeee; }
        #PageContact #Main #ContBox01 #ContSubBox01 form dl:first-of-type dd:last-child, #PageContact #Main #ContBox01 #ContSubBox01 form dl:nth-of-type(3) dd:last-child, #PageContact #Main #ContBox01 #ContSubBox01 form dl:nth-of-type(4) dd:last-child {
          padding: 25px 0 25px 20px; }
        #PageContact #Main #ContBox01 #ContSubBox01 form dl dt, #PageContact #Main #ContBox01 #ContSubBox01 form dl dd {
          display: table-cell;
          vertical-align: top;
          font-size: 20px; }
        #PageContact #Main #ContBox01 #ContSubBox01 form dl dt {
          width: 265px;
          font-weight: 700;
          padding-top: 25px;
          padding-left: 14px;
          line-height: 30px;
          color: #1c2155;
          border-right: 1px solid #eeeeee; }
        #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type {
          width: 46px;
          padding-top: 28px;
          line-height: 30px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span {
            display: block;
            height: 25px;
            line-height: 25px;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 700;
            border-radius: 20px;
            background: #e4e5f0;
            color: #222; }
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span.required {
              background: #d63030;
              color: #fff; }
        #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child {
          width: 690px;
          padding: 18px 0;
          padding-left: 20px;
          line-height: 1; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"],
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"],
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"],
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea {
            width: 540px;
            height: 40px;
            border: 1px solid #cccccc;
            background: #f3f3f3;
            padding-left: 10px;
            border-radius: 0 !important; }
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"]:focus,
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"]:focus,
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"]:focus,
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea:focus {
              background: #fff;
              border: 1px solid #1c2155;
              box-shadow: 1px 1px 1px rgba(85, 105, 178, 0.5), -1px -1px 1px rgba(85, 105, 178, 0.5); }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input::placeholder {
            color: #b4b4b4; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea::placeholder {
            color: #b4b4b4; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text6, #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text5 {
            width: 350px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text7 {
            width: 220px;
            margin-left: 5px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text8 {
            margin-top: 10px;
            width: 100%; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text9 {
            width: 300px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea {
            width: 100%;
            height: 150px;
            padding: 6px 10px;
            resize: none;
            font-size: 20px;
            line-height: 30px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child ul {
            margin: 0;
            font-size: 0; }
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child ul li {
              line-height: 1;
              display: inline-block;
              min-width: 128px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child .checker span {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-right: 2px;
            background: url(../img/contents/check02.png) no-repeat center center;
            background-size: 28px auto;
            vertical-align: middle; }
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child .checker span.checked {
              background: url(../img/contents/check01.png) no-repeat center center;
              background-size: 28px auto; }
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child .checker span input {
              width: 28px;
              height: 28px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child label {
            display: inline-block;
            font-size: 20px;
            vertical-align: middle; }
      #PageContact #Main #ContBox01 #ContSubBox01 form ul {
        position: relative;
        width: 100%;
        margin: 40px 0 0; }
        #PageContact #Main #ContBox01 #ContSubBox01 form ul li.reset {
          position: absolute;
          top: 0;
          left: 0;
          line-height: 84px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"] {
            opacity: 1;
            color: #1e3a9e;
            font-size: 16px;
            text-decoration: underline; }
            #PageContact #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"]:hover {
              text-decoration: none; }
        #PageContact #Main #ContBox01 #ContSubBox01 form ul li #image-btn {
          display: block;
          width: 430px;
          height: 90px;
          margin: 0px auto;
          opacity: 1;
          color: #fff;
          font-size: 24px;
          font-weight: 700;
          line-height: 90px;
          letter-spacing: 0.5px;
          background: #f68813 url(../img/arrow_06.png) no-repeat center right 38px;
          background-size: 32px auto;
          border-bottom: 6px solid #f8c792; }
          #PageContact #Main #ContBox01 #ContSubBox01 form ul li #image-btn:hover {
            background: #f8a145 url(../img/arrow_06.png) no-repeat center right 38px;
            background-size: 32px auto;
            border-bottom: 6px solid #f8a145; }
    #PageContact #Main #ContBox01 #ContSubBox02 {
      padding: 85px 0 100px;
      background: #f3f3f3; }
      #PageContact #Main #ContBox01 #ContSubBox02 h3 {
        font-size: 40px;
        line-height: 70px;
        color: #1c2155;
        text-align: center;
        font-weight: 700;
        margin-bottom: 34px;
        letter-spacing: 1px; }
      #PageContact #Main #ContBox01 #ContSubBox02 h4 {
        background: #18275d;
        margin-top: 37px;
        font-size: 20px;
        color: #fff;
        height: 50px;
        line-height: 50px;
        padding-left: 30px;
        letter-spacing: 1px; }
      #PageContact #Main #ContBox01 #ContSubBox02 h4 + div {
        border: 1px solid #18275d;
        border-top: none;
        padding: 28px 30px;
        letter-spacing: 1px; }
        #PageContact #Main #ContBox01 #ContSubBox02 h4 + div p + p {
          margin-top: 20px; }
        #PageContact #Main #ContBox01 #ContSubBox02 h4 + div ul {
          margin-top: 20px; }
        #PageContact #Main #ContBox01 #ContSubBox02 h4 + div dl {
          margin-top: 20px; }
          #PageContact #Main #ContBox01 #ContSubBox02 h4 + div dl dd, #PageContact #Main #ContBox01 #ContSubBox02 h4 + div dl dt {
            font-size: 20px;
            line-height: 34px; }
            #PageContact #Main #ContBox01 #ContSubBox02 h4 + div dl dd a, #PageContact #Main #ContBox01 #ContSubBox02 h4 + div dl dt a {
              color: #222;
              text-decoration: none; }
  /*
  お問い合わせ pc
  -------------------------------------*/
  /*
  入力確認画面
  -------------------------------------*/
  #PageContact.pageConfirm #Main #ContBox01 > div.innerBasic p {
    font-size: 20px;
    line-height: 34px; }
  #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 {
    margin-top: 52px; }
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dt {
      padding-bottom: 22px; }
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child {
      line-height: 34px;
      padding: 22px 0 22px 18px; }
  /*
  完了画面
  -------------------------------------*/
  #PageContact.pageThanks #Main {
    margin-bottom: 110px; }
    #PageContact.pageThanks #Main #ContBox01 > div.innerBasic p {
      font-size: 20px;
      line-height: 34px; }
    #PageContact.pageThanks #Main #ContBox01 > div.innerBasic .telBox {
      margin: 32px auto 40px; } }

@media print, screen and (max-width: 767px) {
  /*
  404
  -------------------------------------*/
  /*
  ホーム
  -------------------------------------*/
  #PageIndex.pageIndex #MainImg + .contactArea .contactAreaInner {
    padding-top: 15px;
    padding-bottom: 15px; }
    #PageIndex.pageIndex #MainImg + .contactArea .contactAreaInner > div {
      top: -37px; }
  #PageIndex.pageIndex #Main img {
    width: 100%;
    height: auto; }
  #PageIndex.pageIndex #Main .caution {
    font-size: 16px;
    line-height: 25px;
    color: #888;
    position: relative;
    padding-left: 20px; }
    #PageIndex.pageIndex #Main .caution span {
      display: block;
      position: absolute;
      top: 0;
      left: 0; }
  #PageIndex.pageIndex #Main .contBox {
    margin-bottom: 45px;
    padding-top: 45px; }
    #PageIndex.pageIndex #Main .contBox .innerBasic {
      padding: 0 15px; }
    #PageIndex.pageIndex #Main .contBox .titleTxt {
      margin-bottom: 15px; }
      #PageIndex.pageIndex #Main .contBox .titleTxt + p {
        font-size: 20px;
        line-height: 30px; }
    #PageIndex.pageIndex #Main .contBox:nth-of-type(2n) {
      margin-bottom: 0;
      background: #f3f4fb; }
      #PageIndex.pageIndex #Main .contBox:nth-of-type(2n) .innerBasic {
        padding-bottom: 60px; }
      #PageIndex.pageIndex #Main .contBox:nth-of-type(2n) .titleTxt h3 {
        background: #f3f4fb; }
  #PageIndex.pageIndex #Main #ContBox01 .titleTxt + p span {
    font-weight: 700; }
    #PageIndex.pageIndex #Main #ContBox01 .titleTxt + p span.note {
      color: #d63030; }
  #PageIndex.pageIndex #Main #ContBox01 .contSubBox {
    position: relative;
    margin-top: 15px; }
    #PageIndex.pageIndex #Main #ContBox01 .contSubBox + .contSubBox {
      margin-top: 4px; }
    #PageIndex.pageIndex #Main #ContBox01 .contSubBox > span {
      text-indent: -9999px;
      display: block;
      width: 100%;
      max-width: 345px;
      margin: 0 auto;
      height: 175px; }
    #PageIndex.pageIndex #Main #ContBox01 .contSubBox h4 {
      width: 240px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      background: #277977;
      color: #fff;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      letter-spacing: 4px; }
    #PageIndex.pageIndex #Main #ContBox01 .contSubBox div {
      background: #f3f3f3;
      width: 100%;
      padding: 40px 15px 20px;
      position: relative;
      top: -25px; }
      #PageIndex.pageIndex #Main #ContBox01 .contSubBox div p:first-child {
        font-size: 20px;
        line-height: 30px;
        font-weight: 700; }
      #PageIndex.pageIndex #Main #ContBox01 .contSubBox div ul li {
        font-size: 22px;
        line-height: 33px;
        font-weight: 700;
        width: 185px;
        margin: 0 auto;
        padding-left: 30px;
        background: url(../img/contents/index_icon01.png) no-repeat top 4px left;
        background-size: 25px auto; }
      #PageIndex.pageIndex #Main #ContBox01 .contSubBox div p + p, #PageIndex.pageIndex #Main #ContBox01 .contSubBox div ul + p {
        font-size: 16px;
        line-height: 25px;
        margin-top: 10px; }
  #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_01 {
    background: url(../img/contents/index_img01.jpg) no-repeat top center;
    background-size: 180px auto; }
    #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_01 > span {
      background: url(../img/contents/index_txt01.png) no-repeat top 18px left;
      background-size: 88px auto; }
  #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_02 {
    background: url(../img/contents/index_img02.jpg) no-repeat top center;
    background-size: 180px auto; }
    #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_02 > span {
      background: url(../img/contents/index_txt02.png) no-repeat top 18px left;
      background-size: 89px auto; }
    #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_02 h4 {
      background: #135a7e; }
  #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_03 {
    background: url(../img/contents/index_img03.jpg) no-repeat top center;
    background-size: 180px auto; }
    #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_03 > span {
      background: url(../img/contents/index_txt03.png) no-repeat top 18px left;
      background-size: 89px auto; }
    #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_03 h4 {
      background: #483f89; }
    #PageIndex.pageIndex #Main #ContBox01 #ContSubBox01_03 div p:first-of-type {
      letter-spacing: -0.2px; }
  #PageIndex.pageIndex #Main #ContBox02 .innerBasic .titleTxt + p {
    margin-bottom: 5px; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic .titleTxt + p span {
      font-weight: 700; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .titleTxt + p span.note {
        color: #f68813; }
  #PageIndex.pageIndex #Main #ContBox02 .innerBasic > dl {
    display: table;
    width: 100%;
    margin: 23px auto 30px; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic > dl dt, #PageIndex.pageIndex #Main #ContBox02 .innerBasic > dl dd {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
      height: 50px;
      font-size: 20px;
      line-height: 1;
      font-weight: 700;
      text-align: center; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic > dl dt {
      background: #18275d;
      color: #fff;
      letter-spacing: 2.2px; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic > dl dd {
      background: #fff;
      letter-spacing: 1px; }
  #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox {
    margin-top: 30px; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .clickTitle {
      position: relative; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .clickTitle h4 {
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding: 0 50px;
        font-size: 22px;
        font-weight: 700;
        background: #18275d;
        color: #fff;
        text-align: center;
        letter-spacing: 2px; }
        #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .clickTitle h4 span span {
          font-size: 14px;
          line-height: 15px;
          display: block;
          padding-top: 2px;
          text-align: center; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .clickTitle > span {
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        right: 0;
        background: #5569b2 url(../img/arrow_08.png) no-repeat center top 9px;
        background-size: 16px auto;
        text-align: center;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        padding-top: 30px; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox.open .clickTitle > span {
      background: #18275d url(../img/arrow_09.png) no-repeat center top 7px;
      background-size: 16px auto; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox:last-of-type .clickTitle h4 {
      padding: 6px 50px 5px;
      height: auto;
      min-height: 50px;
      line-height: 1;
      display: table;
      width: 100%; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox:last-of-type .clickTitle h4 > span {
        display: table-cell;
        vertical-align: middle; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox figure {
      margin-top: 2px;
      background: #fff;
      padding: 10px; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox figure img {
        width: 100%;
        height: auto; }
    #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl {
      width: 100%;
      background: #fff;
      margin-top: 2px;
      padding: 12px 15px 10px; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl dt, #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl dd {
        font-size: 20px;
        letter-spacing: 0.8px; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl dt {
        line-height: 25px;
        padding-bottom: 5px; }
        #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl dt span {
          font-size: 16px;
          line-height: 1; }
      #PageIndex.pageIndex #Main #ContBox02 .innerBasic .contSubBox .showBox div dl dd {
        font-weight: 700;
        text-align: right; }
  #PageIndex.pageIndex #Main #ContBox03 .mapArea {
    margin-top: 10px; }
    #PageIndex.pageIndex #Main #ContBox03 .mapArea figure {
      max-width: 326px;
      margin: 0 auto; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea figure img {
        width: 100%;
        height: auto; }
    #PageIndex.pageIndex #Main #ContBox03 .mapArea div {
      margin-top: 28px; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl + dl {
        margin-top: 20px; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl dt, #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl dd {
        font-size: 18px;
        line-height: 28px; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl dt {
        font-weight: 700;
        padding-left: 15px;
        position: relative;
        color: #135a7e; }
        #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl dt:before {
          content: "";
          display: inline-block;
          width: 14px;
          height: 14px;
          background: #135a7e;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: 0; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(2) dt {
        color: #383075; }
        #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(2) dt:before {
          background: #383075; }
      #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(3) dt {
        color: #1c8e8b; }
        #PageIndex.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(3) dt:before {
          background: #1c8e8b; }
  #PageIndex.pageIndex #Main #ContBox04 ol {
    list-style: none;
    margin-top: 20px; }
    #PageIndex.pageIndex #Main #ContBox04 ol li {
      width: 100%;
      margin-bottom: 30px; }
      #PageIndex.pageIndex #Main #ContBox04 ol li + li {
        position: relative; }
        #PageIndex.pageIndex #Main #ContBox04 ol li + li:before {
          content: "";
          display: inline-block;
          position: absolute;
          top: -25px;
          left: 0;
          right: 0;
          margin: auto;
          width: 0;
          height: 0;
          border-left: 24px solid transparent;
          border-right: 24px solid transparent;
          border-bottom: 0 solid transparent;
          border-top: 20px solid #cacce3;
          vertical-align: middle; }
      #PageIndex.pageIndex #Main #ContBox04 ol li dl {
        background: #fff;
        overflow: hidden; }
        #PageIndex.pageIndex #Main #ContBox04 ol li dl dt {
          width: 100%;
          display: table;
          min-height: 120px;
          font-size: 20px;
          line-height: 30px;
          font-weight: 700;
          color: #fff;
          table-layout: fixed; }
          #PageIndex.pageIndex #Main #ContBox04 ol li dl dt > span {
            display: table-cell;
            vertical-align: top;
            padding: 15px 14px; }
            #PageIndex.pageIndex #Main #ContBox04 ol li dl dt > span span {
              display: none; }
        #PageIndex.pageIndex #Main #ContBox04 ol li dl dd {
          background: #fff;
          padding: 15px 14px; }
          #PageIndex.pageIndex #Main #ContBox04 ol li dl dd .caution {
            color: #d63030;
            font-weight: 700;
            margin-top: 10px; }
          #PageIndex.pageIndex #Main #ContBox04 ol li dl dd figure {
            margin-top: 7px; }
          #PageIndex.pageIndex #Main #ContBox04 ol li dl dd img {
            width: 100%;
            height: auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(odd) dt:before {
        content: "";
        display: table-cell;
        width: 120px;
        height: 120px;
        vertical-align: top;
        background: #fff; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(even) dt:after {
        content: "";
        display: table-cell;
        width: 120px;
        height: 120px;
        vertical-align: top;
        background: #fff; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:first-child dl dt > span {
        background: #21468f url(../img/contents/index_txt04.png) no-repeat bottom 9px right 12px;
        background-size: 151px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:first-child dl dt:before {
        background: #fff url(../img/contents/index_img09.jpg) no-repeat top left;
        background-size: 120px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(2) dl dt > span {
        background: #21468f url(../img/contents/index_txt05.png) no-repeat bottom 9px right 10px;
        background-size: 153px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(2) dl dt:after {
        background: #fff url(../img/contents/index_img10.jpg) no-repeat top left;
        background-size: 120px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(3) dl dt > span {
        background: #21468f url(../img/contents/index_txt06.png) no-repeat bottom 9px right 10px;
        background-size: 154px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(3) dl dt:before {
        background: #fff url(../img/contents/index_img11.jpg) no-repeat top left;
        background-size: 120px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(4) dl dt > span {
        background: #21468f url(../img/contents/index_txt07.png) no-repeat bottom 9px right 8px;
        background-size: 155px auto; }
      #PageIndex.pageIndex #Main #ContBox04 ol li:nth-child(4) dl dt:after {
        background: #fff url(../img/contents/index_img13.jpg) no-repeat top left;
        background-size: 120px auto; }
  #PageIndex.pageIndex #Main #ContBox04 .clickBox + .clickBox {
    margin-top: 20px; }
  #PageIndex.pageIndex #Main #ContBox04 .clickBox > dt {
    background: #18275d;
    color: #fff;
    height: 50px;
    line-height: 50px;
    padding-left: 12px;
    letter-spacing: 1px;
    font-size: 18px;
    color: #fff;
    position: relative; }
    #PageIndex.pageIndex #Main #ContBox04 .clickBox > dt span {
      display: block;
      text-indent: -9999px;
      width: 50px;
      height: 50px;
      background: #5569b2 url(../img/arrow_08.png) no-repeat center center;
      background-size: 16px auto;
      position: absolute;
      top: 0;
      right: 0; }
  #PageIndex.pageIndex #Main #ContBox04 .clickBox.open > dt span {
    background: #18275d url(../img/arrow_09.png) no-repeat center center;
    background-size: 16px auto; }
  #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd {
    display: none;
    border: 1px solid #18275d;
    border-top: none;
    padding: 15px 14px 22px;
    font-size: 16px;
    line-height: 25px; }
    #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd p {
      font-size: 16px;
      line-height: 25px; }
    #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl {
      font-size: 0;
      margin: 9px 0 8px;
      padding: 10px 15px;
      background: #e4e5f0; }
      #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dt, #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dd {
        font-size: 16px;
        line-height: 25px; }
      #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dt {
        font-weight: 700;
        padding-bottom: 7px; }
      #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dd ul li {
        font-size: 16px;
        line-height: 25px;
        padding-left: 14px;
        position: relative; }
        #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd dl dd ul li:before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          background: #222;
          position: absolute;
          top: 5px;
          margin: auto;
          left: 0; }
    #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div > dl dd ul {
      font-size: 0; }
      #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div > dl dd ul li {
        width: 50%;
        display: inline-block;
        vertical-align: middle; }
        #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div > dl dd ul li:nth-child(2n) {
          padding-left: 30px; }
          #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div > dl dd ul li:nth-child(2n):before {
            left: 15px; }
    #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div + div {
      border-top: 1px solid #18275d;
      margin-top: 15px;
      padding-top: 15px; }
      #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div + div > div {
        display: table;
        width: 100%;
        position: relative;
        margin: 10px 0; }
        #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div + div > div:after {
          content: "";
          width: 10px;
          height: 100%;
          background: #f3f4fb;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto; }
        #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div + div > div dl {
          width: 50%;
          display: table-cell;
          vertical-align: top;
          padding-right: 20px; }
          #PageIndex.pageIndex #Main #ContBox04 .clickBox > dd > div + div > div dl + dl {
            padding-right: 15px;
            padding-left: 20px; }
  #PageIndex.pageIndex #Main #ContBox05 .titleTxt {
    margin-bottom: 25px; }
  #PageIndex.pageIndex #Main #ContBox05 dl dt {
    display: table;
    width: 100%;
    font-size: 20px;
    line-height: 30px;
    min-height: 94px;
    font-weight: 700;
    padding: 17px 0;
    color: #fff;
    text-align: center;
    position: relative; }
    #PageIndex.pageIndex #Main #ContBox05 dl dt span {
      display: table-cell;
      vertical-align: middle; }
    #PageIndex.pageIndex #Main #ContBox05 dl dt:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 0 solid transparent;
      border-top: 10px solid #243a88;
      vertical-align: middle; }
  #PageIndex.pageIndex #Main #ContBox05 dl dd {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    text-align: left;
    background: #f3f3f3;
    padding: 16px 14px; }
  #PageIndex.pageIndex #Main #ContBox05 dl:first-of-type dt {
    background: #243a88 url(../img/contents/index_img14.png) no-repeat center center;
    background-size: 77px auto; }
  #PageIndex.pageIndex #Main #ContBox05 dl:nth-of-type(2) dt {
    background: #243a88 url(../img/contents/index_img15.png) no-repeat center center;
    background-size: 87px auto; }
  #PageIndex.pageIndex #Main #ContBox05 dl:nth-of-type(3) dt {
    background: #243a88 url(../img/contents/index_img16.png) no-repeat center center;
    background-size: 87px auto; }
  #PageIndex.pageIndex #Main #ContBox05 dl:nth-of-type(4) dt {
    background: #243a88 url(../img/contents/index_img17.png) no-repeat center center;
    background-size: 75px auto; }
  #PageIndex.pageIndex #Main #ContBox05 dl:nth-of-type(5) dt {
    background: #243a88 url(../img/contents/index_img18.png) no-repeat center center;
    background-size: 94px auto; }
  #PageIndex.pageIndex #Main #ContBox06 .titleTxt {
    margin-bottom: 20px; }
  #PageIndex.pageIndex #Main #ContBox06 dl {
    width: 100%; }
    #PageIndex.pageIndex #Main #ContBox06 dl + dl {
      margin-top: 18px; }
    #PageIndex.pageIndex #Main #ContBox06 dl dd ul {
      display: table;
      table-layout: fixed;
      width: 100%;
      height: 40px; }
      #PageIndex.pageIndex #Main #ContBox06 dl dd ul li {
        display: table-cell;
        vertical-align: middle;
        font-size: 16px; }
        #PageIndex.pageIndex #Main #ContBox06 dl dd ul li.category {
          width: 94px;
          background: #287a78;
          color: #fff;
          font-weight: 700;
          text-align: center; }
          #PageIndex.pageIndex #Main #ContBox06 dl dd ul li.category.news {
            background: #327699; }
        #PageIndex.pageIndex #Main #ContBox06 dl dd ul li.date {
          background: #e4e5f0;
          font-family: 'Lato', sans-serif;
          font-weight: 400;
          letter-spacing: 2.5px;
          padding: 0 9px;
          text-align: right; }
    #PageIndex.pageIndex #Main #ContBox06 dl dt {
      background: #fff; }
      #PageIndex.pageIndex #Main #ContBox06 dl dt a {
        display: block;
        padding: 15px 14px;
        width: 100%;
        font-size: 18px;
        line-height: 28px;
        color: #1e3a9e;
        text-decoration: underline; }
  #PageIndex.pageIndex #Main #ContBox06 .linkBtn {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 18px auto 0; }
    #PageIndex.pageIndex #Main #ContBox06 .linkBtn a {
      display: block;
      width: 100%;
      height: 100%;
      background: #5569b2 url(../img/aroow_01.png) no-repeat center right 15px;
      background-size: 8px auto;
      font-size: 16px;
      color: #fff;
      letter-spacing: 1px; }
  /*
  会社案内 sp
  -------------------------------------*/
  #PageCompany.pageIndex #Main {
    margin: 30px 15px 80px; }
    #PageCompany.pageIndex #Main .contBox {
      margin-bottom: 30px; }
      #PageCompany.pageIndex #Main .contBox .titleTxt {
        margin-bottom: 14px; }
    #PageCompany.pageIndex #Main #ContBox01 .txt p + p {
      margin-top: 20px; }
    #PageCompany.pageIndex #Main #ContBox01 .txt p.note {
      font-size: 20px;
      line-height: 30px;
      color: #d63030;
      font-weight: 700; }
    #PageCompany.pageIndex #Main #ContBox01 dl {
      margin-top: 22px; }
      #PageCompany.pageIndex #Main #ContBox01 dl dt {
        text-indent: -9999px;
        background: url(../img/logo.png) no-repeat top left;
        background-size: 158px auto;
        width: 158px;
        height: 70px;
        margin-left: auto; }
      #PageCompany.pageIndex #Main #ContBox01 dl dd {
        font-size: 15px;
        line-height: 19px;
        font-weight: 700;
        text-align: right;
        margin-top: 6px;
        letter-spacing: 1px; }
    #PageCompany.pageIndex #Main #ContBox02 .titleTxt {
      margin-bottom: 20px; }
    #PageCompany.pageIndex #Main #ContBox02 dl dd, #PageCompany.pageIndex #Main #ContBox02 dl dt {
      font-size: 18px;
      line-height: 28px;
      padding: 11px 14px 21px; }
    #PageCompany.pageIndex #Main #ContBox02 dl dt {
      font-weight: 700;
      color: #1c2155;
      background: #f3f3f3;
      padding: 11px 14px; }
    #PageCompany.pageIndex #Main #ContBox02 dl dd a {
      text-decoration: underline; }
    #PageCompany.pageIndex #Main #ContBox03 p {
      font-size: 20px;
      line-height: 30px; }
    #PageCompany.pageIndex #Main #ContBox03 .mapArea {
      margin-top: 10px; }
      #PageCompany.pageIndex #Main #ContBox03 .mapArea figure {
        max-width: 326px;
        margin: 0 auto; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea figure img {
          width: 100%;
          height: auto; }
      #PageCompany.pageIndex #Main #ContBox03 .mapArea div {
        margin-top: 28px; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl + dl {
          margin-top: 20px; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl dt, #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl dd {
          font-size: 18px;
          line-height: 28px; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl dt {
          font-weight: 700;
          padding-left: 15px;
          position: relative;
          color: #135a7e; }
          #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl dt:before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 14px;
            background: #135a7e;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(2) dt {
          color: #383075; }
          #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(2) dt:before {
            background: #383075; }
        #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(3) dt {
          color: #1c8e8b; }
          #PageCompany.pageIndex #Main #ContBox03 .mapArea div dl:nth-of-type(3) dt:before {
            background: #1c8e8b; }
  /*
  お問い合わせ sp 共通
  -------------------------------------*/
  #PageContact #Main {
    margin-top: 27px; }
    #PageContact #Main #ContBox01 > div.innerBasic {
      padding: 0 15px; }
      #PageContact #Main #ContBox01 > div.innerBasic p {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 1.2px; }
        #PageContact #Main #ContBox01 > div.innerBasic p span {
          color: #f68813;
          font-weight: 700; }
        #PageContact #Main #ContBox01 > div.innerBasic p.note {
          color: #d63030;
          font-size: 16px;
          font-weight: 700;
          margin-top: 8px;
          letter-spacing: 0; }
      #PageContact #Main #ContBox01 > div.innerBasic .telBox {
        width: 100%;
        margin: 22px auto 20px;
        border: 1px solid #dddddd; }
        #PageContact #Main #ContBox01 > div.innerBasic .telBox a {
          display: block;
          padding: 8px 0 13px; }
        #PageContact #Main #ContBox01 > div.innerBasic .telBox dl {
          text-align: center; }
          #PageContact #Main #ContBox01 > div.innerBasic .telBox dl dt {
            font-size: 35px;
            line-height: 45px;
            color: #0c0f3a;
            font-family: 'Lato', sans-serif;
            font-weight: 400;
            letter-spacing: 0.8px;
            text-decoration: underline; }
            #PageContact #Main #ContBox01 > div.innerBasic .telBox dl dt:before {
              content: "";
              display: inline-block;
              vertical-align: middle;
              width: 29px;
              height: 17px;
              background: url(../img/tel_icon.png) no-repeat bottom left;
              background-size: 29px auto;
              margin-right: 4px; }
          #PageContact #Main #ContBox01 > div.innerBasic .telBox dl dd {
            text-align: center;
            font-size: 16px;
            line-height: 1;
            color: #666;
            letter-spacing: 0.5px;
            margin-top: 5px; }
        #PageContact #Main #ContBox01 > div.innerBasic .telBox + div {
          text-align: center; }
          #PageContact #Main #ContBox01 > div.innerBasic .telBox + div a {
            font-size: 18px;
            color: #1e3a9e;
            text-decoration: underline; }
    #PageContact #Main #ContBox01 #ContSubBox01 {
      margin-top: 47px;
      margin-bottom: 50px;
      padding: 0 15px; }
      #PageContact #Main #ContBox01 #ContSubBox01 .titleTxt {
        margin-bottom: 0; }
      #PageContact #Main #ContBox01 #ContSubBox01 form dl {
        width: 100%;
        color: #222;
        font-size: 0;
        margin-top: 19px; }
        #PageContact #Main #ContBox01 #ContSubBox01 form dl dt, #PageContact #Main #ContBox01 #ContSubBox01 form dl dd {
          font-size: 18px; }
        #PageContact #Main #ContBox01 #ContSubBox01 form dl dt {
          font-weight: 700;
          line-height: 28px;
          color: #1c2155;
          display: inline-block;
          vertical-align: middle;
          padding-left: 9px; }
        #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type {
          display: inline-block;
          vertical-align: middle;
          width: 46px;
          line-height: 30px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span {
            display: block;
            height: 25px;
            line-height: 25px;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 700;
            border-radius: 20px;
            background: #e4e5f0;
            color: #222; }
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:first-of-type span.required {
              background: #d63030;
              color: #fff; }
        #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child {
          margin-top: 10px;
          width: 100%;
          line-height: 1; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"],
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"],
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"],
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea {
            width: 100%;
            height: 50px;
            border: 1px solid #cccccc;
            background: #f3f3f3;
            padding-left: 10px;
            border-radius: 0 !important; }
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="text"]:focus,
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="email"]:focus,
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input[type="tel"]:focus,
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea:focus {
              background: #fff;
              border: 1px solid #1c2155;
              box-shadow: 1px 1px 1px rgba(85, 105, 178, 0.5), -1px -1px 1px rgba(85, 105, 178, 0.5); }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child input::placeholder {
            color: #b4b4b4; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea::placeholder {
            color: #b4b4b4; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text7 {
            width: 150px;
            margin-left: 8px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child #text8 {
            margin-top: 10px;
            width: 100%;
            padding-right: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child textarea {
            width: 100%;
            height: 200px;
            padding: 6px 10px;
            resize: none;
            font-size: 20px;
            line-height: 30px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child ul {
            margin: 0;
            font-size: 0;
            border: 1px solid #eeeeee; }
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child ul li {
              line-height: 1;
              padding: 10px 9px; }
              #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child ul li + li {
                border-top: 1px solid #eeeeee; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child .checker span {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-right: 2px;
            background: url(../img/contents/check02.png) no-repeat center center;
            background-size: 28px auto;
            vertical-align: middle; }
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child .checker span.checked {
              background: url(../img/contents/check01.png) no-repeat center center;
              background-size: 28px auto; }
            #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child .checker span input {
              width: 28px;
              height: 28px; }
          #PageContact #Main #ContBox01 #ContSubBox01 form dl dd:last-child label {
            display: inline-block;
            font-size: 18px;
            vertical-align: middle; }
      #PageContact #Main #ContBox01 #ContSubBox01 form ul {
        position: relative;
        width: 100%;
        margin: 27px 0 0; }
        #PageContact #Main #ContBox01 #ContSubBox01 form ul li.reset {
          margin-top: 25px;
          text-align: center; }
          #PageContact #Main #ContBox01 #ContSubBox01 form ul li.reset input[type="button"] {
            opacity: 1;
            color: #1e3a9e;
            font-size: 16px;
            text-decoration: underline; }
        #PageContact #Main #ContBox01 #ContSubBox01 form ul li #image-btn {
          display: block;
          width: 100%;
          height: 75px;
          margin: 0px auto;
          opacity: 1;
          color: #fff;
          font-size: 20px;
          font-weight: 700;
          line-height: 75px;
          letter-spacing: 0.5px;
          background: #f68813 url(../img/arrow_06.png) no-repeat center right 22px;
          background-size: 32px auto;
          border-bottom: 4px solid #f8c792; }
    #PageContact #Main #ContBox01 #ContSubBox02 {
      padding: 46px 15px 65px;
      background: #f3f3f3; }
      #PageContact #Main #ContBox01 #ContSubBox02 h3 {
        font-size: 28px;
        line-height: 35px;
        color: #1c2155;
        text-align: center;
        font-weight: 700;
        margin-bottom: 36px;
        letter-spacing: 1px; }
      #PageContact #Main #ContBox01 #ContSubBox02 h3 + p span {
        display: block;
        margin-top: 20px; }
      #PageContact #Main #ContBox01 #ContSubBox02 h4 {
        background: #18275d;
        margin-top: 28px;
        font-size: 18px;
        line-height: 25px;
        color: #fff;
        padding: 13px 12px;
        letter-spacing: 1px; }
      #PageContact #Main #ContBox01 #ContSubBox02 h4 + div {
        border: 1px solid #18275d;
        border-top: none;
        padding: 24px 14px; }
        #PageContact #Main #ContBox01 #ContSubBox02 h4 + div p + p {
          margin-top: 20px; }
        #PageContact #Main #ContBox01 #ContSubBox02 h4 + div ul {
          margin-top: 20px; }
        #PageContact #Main #ContBox01 #ContSubBox02 h4 + div dl {
          margin-top: 20px; }
          #PageContact #Main #ContBox01 #ContSubBox02 h4 + div dl dd, #PageContact #Main #ContBox01 #ContSubBox02 h4 + div dl dt {
            font-size: 18px;
            line-height: 28px; }
            #PageContact #Main #ContBox01 #ContSubBox02 h4 + div dl dd a, #PageContact #Main #ContBox01 #ContSubBox02 h4 + div dl dt a {
              text-decoration: underline; }
  /*
  お問い合わせ
  -------------------------------------*/
  /*
  入力確認画面
  -------------------------------------*/
  #PageContact.pageConfirm #Main #ContBox01 > div.innerBasic p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0; }
  #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 {
    margin-top: 24px; }
    #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl {
      border-bottom: 1px solid #eee;
      padding: 18px 0;
      margin-top: 0; }
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl:first-of-type {
        border-top: 1px solid #eee; }
      #PageContact.pageConfirm #Main #ContBox01 #ContSubBox01 form dl dd:last-child {
        line-height: 22px; }
  /*
  完了画面
  -------------------------------------*/
  #PageContact.pageThanks #Main #ContBox01 {
    margin-bottom: 55px; }
    #PageContact.pageThanks #Main #ContBox01 > div.innerBasic p {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0; }
    #PageContact.pageThanks #Main #ContBox01 > div.innerBasic .telBox {
      margin-bottom: 34px; }
      #PageContact.pageThanks #Main #ContBox01 > div.innerBasic .telBox + div a {
        font-size: 16px; } }
